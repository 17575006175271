<template>
  <swiper class="swiper">
    <swiper-slide>
        <img class="hideMobile" src="@/assets/img/gallery1.png" alt="" draggable="false"/>
        <img class="hideDesktop"  src="@/assets/img/colazione-mob.png" alt="" draggable="false"/>
    </swiper-slide>
    <swiper-slide>
        <img src="@/assets/img/imag2.jpg" alt="" draggable="false"/>
    </swiper-slide>
    <swiper-slide>
         <img src="@/assets/img/image3.jpg" alt="" draggable="false"/>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "swiper-example-default",
  title: "Default",
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss" scoped></style>

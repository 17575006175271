<template>
  <swiper class="swiper hideDesktop viewTablet row" :options="swiperOption">
    <swiper-slide>
      <div style="max-height: 250px; height: 100%;" class="col-xs-12 box-carousel">
        <img src="@/assets/img/tours.jpeg" alt="" draggable="false"/>
        <span>Guided Tours</span>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div style="max-height: 250px; height: 100%;" class="col-xs-12 box-carousel">
        <img src="@/assets/img/wine.jpeg" alt="" draggable="false"/>
        <span>Wine Tasting</span>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div style="max-height: 250px; height: 100%;" class="col-xs-12 box-carousel">
        <img src="@/assets/img/aperit.jpeg" alt="" draggable="false"/>
        <span>Aperitrek</span>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "moobileExperience",
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 100,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>

</style>>


<template>
  <header>
    <router-link class="back" to="/">
      <img src="@/assets/img/manicula + 2.png" draggable="false" />
    </router-link>
    <div class="navigation">
      <nav>
        <ul class="first">
          <li><a href="#concept">concept</a></li>
          <li><a href="#bed">bed&amp;books</a></li>
          <li><a href="#gallery">gallery</a></li>
        </ul>
        <ul class="second">
          <li><a href="#experience">experience</a></li>
          <li><a href="#contacts">contacts</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  nome: "Header",
};
</script>

<template>
  <swiper style="100vw" class="swiper" :options="swiperOption">
    <swiper-slide>
      <div style="position: relative">
        <img src="@/assets/img/room1.png" alt="" draggable="false"/>
        <img class="bandiera" src="@/assets/img/bandiera.svg" alt="" draggable="false"/>
      </div>
      <div class="room">
        <h5>ROOM 1</h5>
        <span class="mb-3 mt-3" style="font-size: 14px">(up to 2 people)</span>
        <ul>
          <li>DOUBLE BED</li>
          <li>PRIVATE BATHROOM</li>
          <li>BREAKFAST INCLUDED</li>
        </ul>
        <button><a href="https://sognalibro.beddy.io/" target="_back">Book Now</a></button>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div style="position: relative">
        <img src="@/assets/img/CAMERA 2 (2).jpg" alt="" draggable="false"/>
        <img class="bandiera" src="@/assets/img/bandiera.svg" alt="" draggable="false"/>
      </div>
      <div class="room">
        <h5>ROOM 2</h5>
        <span class="mb-3 mt-3" style="font-size: 14px">(up to 2 people)</span>
        <ul>
          <li>1 DOUBLE BED <br />or 2 SINGLE BEDS</li>
          <li>PRIVATE BATHROOM</li>
          <li>BREAKFAST INCLUDED</li>
        </ul>
        <button><a href="https://sognalibro.beddy.io/" target="_back">Book Now</a></button>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div style="position: relative">
        <img src="@/assets/img/apartment.jpg" alt="" draggable="false"/>
        <img class="bandiera" src="@/assets/img/bandiera.svg" draggable="false" />
      </div>
      <div class="room">
        <h5>APARTMENT</h5>
        <span class="mb-3 mt-3" style="font-size: 14px">(up to 6 people)</span>
        <ul>
          <li>2 Double rooms with private bathroom</li>
          <li>1 sofa bed (double)</li>
          <li>breakfast included</li>
        </ul>
        <button><a href="https://sognalibro.beddy.io/" target="_back">Book Now</a></button>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "CarouselRooms",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 2,
        spaceBetween: 30,
        breakpoints: {
          // when window width is <= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is <= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 50,
          },

          // when window width is <= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style></style>

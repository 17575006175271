<template>
  <div class="intro">
    <div class="segno">
      <img src="@/assets/img/Frame (1).svg" alt="" draggable="false" />
    </div>
    <div class="mano">
      <router-link to="/home">
        <img src="@/assets/img/manicula + 1.png" alt="" draggable="false" />
      </router-link>
    </div>
    <div class="testo">
      <img src="@/assets/img/sognalibro.svg" alt="" draggable="false" />
      <img src="@/assets/img/bab.svg" alt="" draggable="false" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Intro",
  components: {},
};
</script>

<template>
  <div>
    <Header />
    <div class="box-img">
      <img class="hideMobile" src="@/assets/img/lettere.svg" draggable="false" />
      <img class="hideDesktop" src="@/assets/img/alfabeto-corto.png" draggable="false" />
    </div>
    <div id="concept" class="container-md container-lg">
      <div class="row virgolette">
        <div class="capitolo col-xs-12 col-md-4 col-lg-6">
          <p>[01] concept</p>
        </div>
        <div class="sognalibro col-xs-12 col-md-8 col-lg-6">
          <div class="title">
            <h3>Sognalibro</h3>
            <span>[so-gna-lì-bro] n.m.</span>
          </div>
          <p>
            In retrieving the dispersed library of the old family residence,
            located in an Art Nouveau building in the centre of Ragusa, just a
            few steps from the cathedral of San Giovanni, we conceived
            Sognalibro bed and books: a short-term rental accommodation
            consisting of 2 double rooms with private bathroom and a living room
            with double sofa bed.
          </p>
        </div>
      </div>
      <div class="row mobile-flud mb-5">
        <div class="col-xs-12 col-md-12 col-lg-8 libreria">
          <img class="hideMobile" src="@/assets/img/libreria.png" draggable="false" />
          <img class="hideDesktop" src="@/assets/img/libr-mob.png" draggable="false" />
        </div>
        <div
          style="position: relative; z-index: 5"
          class="col-xs-12 col-md-12 col-lg-4 d-flex flex-column align-items-center justify-content-center"
        >
          <img class="mb-3 hideMobile hideTablet" src="@/assets/img/circle.svg" draggable="false" />
          <p class="circle-text">
            Its interior design, lovingly restored with traditional cement tile
            flooring alongside contemporary furniture, envisages a "between the
            lines" accommodation that will intrigue the curious traveller.
          </p>
          <div class="apice hideMobile"></div>
        </div>
      </div>
    </div>
    <div id="bed">
      <div id="books" class="container">
        <div class="row">
          <div class="capitolo col-12 text-end">
            <p>[02] bed&amp;books</p>
          </div>
        </div>
        <div  class="row hideMobile">
          
          <div class="col-xs-12 col-md-12  col-lg-3  volumes">
            <p>
              Over 700 volumes including classics, novels and ancient texts in
              numerous languages, together with a careful selection of books on
              the region and travel literature. The flat has a fully-equipped
              kitchen, smart TV, reading corner and self-check in.
            </p>
            <img class=" hideTablet" src="@/assets/img/g.svg" draggable="false" />
          </div>
          <div class="col-md-12  col-lg-8 offset-lg-1 p-0">
            <CarouselRooms />
          </div>
        </div>
      </div>
      <div class="container-fluid room-mob hideDesktop">
        <MobileRoom />
      </div>
    </div>
    <div id="gallery">
      <div class="container">
        <div class="row">
          <div class="capitolo col-12 text-start">
            <p>[03] gallery</p>
          </div>
        </div>
      </div>
      <div  class="container-fluid">
        <div class="row">
          <div class="col-12 p-0">
            <CarouselGallery />
          </div>
          <div class="col-12 mb-5">
            <button><a href="https://sognalibro.beddy.io/" target="_back">Book Now</a></button>
          </div>
          <div class="col-12 mt-5 hideMobile">
            <img src="@/assets/img/a.svg" alt="" draggable="false">
          </div>
          <div class="col-xs-12 hideDesktop section-mob">
            <div class="circle">
              <img src="@/assets/img/circle.svg" draggable="false"/>
            </div>
            <p>
              Over 700 volumes including classics, novels and ancient texts in
              numerous languages, together with a careful selection of books on
              the region and travel literature. The flat has a fully-equipped
              kitchen, smart TV, reading corner and self-check in.
            </p>
            <img class="apice" src="@/assets/img/apice.svg" draggable="false" />
          </div>
        </div>
      </div>
    </div>
    <div id="experience">
      <div class="container">
        <div class="row">
          <div class="capitolo col-12 text-start">
            <p>[04] experience</p>
          </div>
        </div>
        <div class="row hideTablet hideMobile gal-exp">
          <div class="col-lg-4">
            <img src="@/assets/img/tours.jpeg" draggable="false">
            <h3>Guided Tours</h3>
          </div>
          <div class="col-lg-4">
            <img src="@/assets/img/wine.jpeg" draggable="false">
            <h3>Wine Tasting</h3>
          </div>
          <div class="col-lg-4">
            <img src="@/assets/img/aperit.jpeg" draggable="false">
            <h3>Aperitrek</h3>
          </div>
        </div>
        <!-- component mobile -->
        <MobileExperience />
        <div class="row mt-5">
          <div class="col-xs-10 offset-xs-1 col-md-8 offset-md-2">
            <div class="graffe">
              <img src="@/assets/img/sx.svg" draggable="false">
              <p>
                Discover the beauty of our territory through authentic experiences that will make you experience the artistic culture, the food and wine tradition, and the naturalistic beauties of Sicily.
              </p>
              <img src="@/assets/img/dx.svg" draggable="false">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <a href="https://goo.gl/maps/saEZT1A74WLzE7sX6" target="_blanck">
            <img class="w-100 hideMobile" src="@/assets/img/mappa-01.png" draggable="false">
          </a>
          <img class="w-100 hideDesktop" src="@/assets/img/mappa-mobile.png" draggable="false">
        </div>
      </div>
    </div>
    <div id="contacts">
      <Footer />
    </div>
  </div>
</template>

<script>

import Header from "../components/Header.vue";
import CarouselRooms from "../components/CarouselRooms.vue";
import MobileRoom from "../components/MobileRoom.vue";
import CarouselGallery from "../components/CarouselGallery.vue";
import Footer from "../components/Footer.vue";
import MobileExperience from '../components/MobileExperience.vue';
export default {
  name: "Home",
  components: {
    Header,
    CarouselRooms,
    MobileRoom,
    CarouselGallery,
    Footer,
    MobileExperience
  },
  data() {
    return {
      
    };
  },
};
</script>

<style></style>

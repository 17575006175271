<template>
  <swiper class="swiper row" :options="swiperOption">
    <swiper-slide>
      <div class="col-xs-12 mb-4 p-0">
        <div style="position: relative">
          <img class="stanza" src="@/assets/img/room1.png" draggable="false" />
          <img class="bandiera" src="@/assets/img/bandiera.svg" draggable="false"/>
        </div>
        <div class="room">
          <h5>ROOM 1</h5>
          <span class="mb-3 mt-4" style="font-size: 14px">(up to 2 people)</span>
          <ul>
            <li>DOUBLE BED</li>
            <li>PRIVATE BATHROOM</li>
            <li>BREAKFAST INCLUDED</li>
          </ul>
          <button>
            <a
              href="https://sognalibro.beddy.io/"
              target="_back"
              >Book Now</a
            >
          </button>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="col-xs-12 mb-4 p-0">
        <div style="position: relative">
          <img class="stanza" src="@/assets/img/CAMERA 2 (2).jpg" draggable="false" />
          <img class="bandiera" src="@/assets/img/bandiera.svg" draggable="false" />
        </div>
        <div class="room">
          <h5>ROOM 2</h5>
          <span class="mb-3 mt-4" style="font-size: 14px">(up to 2 people)</span>
          <ul>
            <li>1 DOUBLE BED <br /> or 2 SINGLE BEDS</li>
            <li>PRIVATE BATHROOM</li>
            <li>BREAKFAST INCLUDED</li>
          </ul>
          <button>
            <a
              href="https://sognalibro.beddy.io/"
              target="_back"
              >Book Now</a
            >
          </button>
        </div>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="col-xs-12 mb-4 p-0">
        <div style="position: relative">
          <img class="stanza" src="@/assets/img/apartment.jpg" draggable="false" />
          <img class="bandiera" src="@/assets/img/bandiera.svg" draggable="false" />
        </div>
        <div class="room">
          <h5>APARTMENT</h5>
          <span class="mb-3 mt-4" style="font-size: 14px">(up to 6 people)</span>
          <ul>
            <li>2 Double rooms with private bathroom</li>
            <li>1 sofa bed (double)</li>
            <li>breakfast included</li>
          </ul>
          <button>
            <a
              href="https://sognalibro.beddy.io/"
              target="_back"
              >Book Now</a
            >
          </button>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "MobileRoom",
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 100,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style></style>
